import {Button, Tooltip} from "@mui/material";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import React from "react";

export default function AutoCutSilence({onClick}) {
  return (
    <Tooltip title="Cmd/Ctrl + Shift + Backspace">
      <Button
        sx={{
          mr: 2,
          mb: 5,
          backgroundColor: '#ffa000', // 警告按钮使用橙色
          color: 'white', // 黑色文字
          '&:hover': {
            backgroundColor: '#ff8f00' // 悬停时颜色变化
          }
        }}
        variant="contained"
        onClick={onClick}
        startIcon={<VolumeOffIcon />}
      >
        自动剪切静音部分
      </Button>
    </Tooltip>
  )
}
