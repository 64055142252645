import {useCallback, useRef} from "react";
import {useAppData} from "../contexts/AppContext";

export default function useAudioBuffer(audioContextRef) {
  const currentAudioBlobRef = useRef(null);
  const {file} = useAppData()

  const getAudioBuffer = useCallback(async () => {
    const currentAudioFile = URL.createObjectURL(currentAudioBlobRef.current || file);
    const response = await fetch(currentAudioFile);
    const arrayBuffer = await response.arrayBuffer();
    return await audioContextRef.current.decodeAudioData(arrayBuffer)
  }, [audioContextRef, file])

  return {
    currentAudioBlobRef,
    getAudioBuffer
  }
}
