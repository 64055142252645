import {Chip} from "@mui/material";
import React from "react";
import {mapOriginalToCurrent} from "../../utils/audioUtils";
import {useAppData} from "../../contexts/AppContext";

export default function Word(props) {
  const {data, wsRegions, onClick} = props
  const {start, end} = data
  const {cuts, totalDuration, cutPointer} = useAppData();

  const currentRegions = wsRegions?.regions?.map?.(region => ({
    start: region.start,
    end: region.end
  }));

  const currentStart = mapOriginalToCurrent(start, cuts, totalDuration, cutPointer);
  const currentEnd = mapOriginalToCurrent(end, cuts, totalDuration, cutPointer);

  const isHighlighted = currentRegions?.some?.(region => {
    return (
      (currentStart >= region.start && currentStart < region.end) ||  // 字词的起始时间在选区内
      (currentEnd > region.start && currentEnd <= region.end) ||      // 字词的结束时间在选区内
      (currentStart < region.start && currentEnd > region.end)        // 字词跨越整个选区
    );
  });

  return (
    <Chip
      label={data.word}
      variant={isHighlighted ? 'filled' : 'outlined'}
      color={isHighlighted ? 'primary' : 'default'}
      onClick={(e) => onClick(e, isHighlighted)}
    />
  )
}
