import {createContext, useContext, useReducer} from 'react';
import {getRemainingSegments, mapCutSegmentsToOriginal} from "../utils/audioUtils";

export const initialState = {
  videoPath: '',
  transcriptionData: [],
  cuts: [],
  remainingSegments: [],
  cutPointer: -1,
  totalDuration: 0,
  file: null,
};

export function reducer(state, action) {
  switch (action.type) {
    case 'SET_TRANSCRIPTION_DATA':
      return {
        ...state,
        transcriptionData: action.payload,
      };
    case 'ADD_CUT':
      let cutSegments = action.payload
      if (state.cuts.length !== 0) {
        cutSegments = mapCutSegmentsToOriginal(state.remainingSegments, cutSegments);
      }

      const newCuts = [...state.cuts.slice(0, state.cutPointer + 1), cutSegments];
      const newRemainingSegments = getRemainingSegments(state.totalDuration, newCuts.flat());

      return { ...state, cuts: newCuts, remainingSegments: newRemainingSegments, cutPointer: state.cutPointer + 1 };
    case 'SET_TOTAL_DURATION':
      if (state.totalDuration > 0) {
        return state;
      }
      return { ...state, totalDuration: action.payload };
    case 'RESET_CUTS':
      return { ...initialState, totalDuration: state.totalDuration };
    case 'SET_CUT_POINTER':
      const cutsToRestore = action.payload === -1 ? [] : state.cuts.slice(0, action.payload + 1).flat();
      const updatedRemainingSegments = getRemainingSegments(state.totalDuration, cutsToRestore);
      return { ...state, cutPointer: action.payload, remainingSegments: updatedRemainingSegments };
    case 'SET_FILE':
      return { ...state, file: action.payload }
    case 'SET_VIDEO_PATH':
      return { ...state, videoPath: action.payload }
    default:
      return state;
  }
}

export const DataContext = createContext(null);
export function useAppData() {
  return useContext(DataContext)
}

export const DispatchContext = createContext(() => {});
export function useAppDispatch() {
  return useContext(DispatchContext)
}

export default function AppProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState, s => s);

  return (
    <DataContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        {children}
      </DispatchContext.Provider>
    </DataContext.Provider>
  )
}
