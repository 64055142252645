import React from 'react';
import { Drawer, List, ListItem, ListItemText, Button, Divider, Typography } from '@mui/material';
import { useAppData } from "../../contexts/AppContext";

const HistoryPanel = ({ open, onClose, onRestore }) => {
  const { cuts, cutPointer } = useAppData();

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{ style: { width: 300 } }}
    >
      <div style={{ padding: '16px' }}>
        <Typography variant="h6">历史剪辑</Typography>
        <List>
          <ListItem selected={cutPointer === -1}>
            <ListItemText primary="原始音频" />
            <Button
              variant="outlined"
              onClick={() => onRestore(-1)}
              disabled={cutPointer === -1}
            >
              恢复
            </Button>
          </ListItem>
          <Divider />
          {cuts.map((cut, index) => (
            <div key={index}>
              <ListItem selected={cutPointer === index}>
                <ListItemText primary={cutPointer === index ? `当前剪辑` : `剪辑 ${index + 1}`} />
                <Button
                  variant="outlined"
                  onClick={() => onRestore(index)}
                  disabled={cutPointer === index}
                >
                  恢复
                </Button>
              </ListItem>
              {index < cuts.length - 1 && <Divider />}
            </div>
          ))}
        </List>
      </div>
    </Drawer>
  );
};

export default HistoryPanel;
