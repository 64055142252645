import React, {useCallback, useEffect, useRef, useState} from 'react';
import WaveSurfer from "wavesurfer.js";
import {createOrUpdateRegions, removeRegions, startFreshRegions} from "./utils/region";
import {bufferToWave, cutAudio, detectSilenceToCut} from "./utils/audioUtils";
import Transcription from "./components/Transcription/Transcription";
import useUndoRedo from "./hooks/useUndoRedo";
import {useAppData, useAppDispatch} from "./contexts/AppContext";
import {Box, Stack} from "@mui/material";
import HistoryPanel from './components/HistoryPanel/HistoryPanel';
import useAudioBuffer from "./hooks/useAudioBuffer";
import useKeyboardShortcuts from "./hooks/useKeyboardShortcuts";
import {baseURL} from "./App";
import WaveForm from "./components/WaveForm/WaveForm";
import {AutoCutSilence, CutSlice, Export, PlayPause, PlaySlice, ViewHistory} from "./components/Buttons";
import ZoomSlider from "./components/ZoomSlider/ZoomSlider";

function MainApp({file}) {
  const waveformRef = useRef(null);
  const wavesurferRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(0);
  const audioContextRef = useRef(new (window.AudioContext || window.webkitAudioContext)());
  const {currentAudioBlobRef, getAudioBuffer} = useAudioBuffer(audioContextRef);
  const [wsRegions, setWsRegions] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isExporting, setIsExporting] = useState(false);
  const {handleRestore} = useUndoRedo(currentAudioBlobRef, audioContextRef, wavesurferRef, startFreshRegions, setWsRegions);
  const dispatch = useAppDispatch();
  const appData = useAppData()

  const videoPath = appData.videoPath

  useEffect(() => {
    const setupWaveSurfer = (url) => {
      wavesurferRef.current = WaveSurfer.create({
        container: waveformRef.current,
        waveColor: 'violet',
        progressColor: 'purple',
        backend: 'MediaElementWebAudio',
        url: url,
      });

      wavesurferRef.current.on('play', () => setIsPlaying(true));
      wavesurferRef.current.on('pause', () => setIsPlaying(false));
      wavesurferRef.current.on('finish', () => setIsPlaying(false));

      wavesurferRef.current.on('ready', () => {
        const duration = wavesurferRef.current.getDuration();
        wavesurferRef.current.zoom(zoomLevel);

        dispatch({type: 'SET_TOTAL_DURATION', payload: duration});
      });

      startFreshRegions(wavesurferRef, setWsRegions);
    };

    if (!initialized) {
      const url = URL.createObjectURL(file);
      setupWaveSurfer(url);
      setInitialized(true);
    }
  }, [dispatch, file, initialized, zoomLevel]);

  const processAndCutAudio = useCallback(async (audioBuffer, cutSegments) => {
    const newBlob = await cutAudio(audioBuffer, audioContextRef.current, cutSegments);

    wavesurferRef.current.empty();
    await new Promise(resolve => {
      wavesurferRef.current.loadBlob(newBlob);
      wavesurferRef.current.on('ready', resolve);
    });

    currentAudioBlobRef.current = newBlob;

    dispatch({type: 'ADD_CUT', payload: cutSegments});

    startFreshRegions(wavesurferRef, setWsRegions);
  }, [currentAudioBlobRef, dispatch]);

  const handleCut = useCallback(async () => {
    const regions = [...wsRegions.regions];
    if (!regions?.length) return;
    const cutSegments = regions.map(region => [region.start, region.end]);
    const audioBuffer = await getAudioBuffer();
    await processAndCutAudio(audioBuffer, cutSegments);
  }, [getAudioBuffer, processAndCutAudio, wsRegions]);

  const handleAutoCut = useCallback(async () => {
    const maxSilenceDuration = 3;
    if (!file && !currentAudioBlobRef.current) {
      alert("No audio file selected or loaded.");
      return;
    }

    const audioBuffer = await getAudioBuffer();
    const silenceSegments = detectSilenceToCut(audioBuffer, maxSilenceDuration, 1);

    if (silenceSegments.length === 0) {
      alert(`未检测到超过${maxSilenceDuration}秒的静音片段`);
      return;
    }
    await processAndCutAudio(audioBuffer, silenceSegments);
  }, [currentAudioBlobRef, file, getAudioBuffer, processAndCutAudio]);

  const exportAudio = useCallback(async () => {
    const audioBuffer = await getAudioBuffer();
    const waveBlob = bufferToWave(audioBuffer);
    const url = URL.createObjectURL(waveBlob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = 'exported_audio.wav';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  }, [getAudioBuffer])

  const handleExport = useCallback(async () => {
    if (videoPath) {
      setIsExporting(true)
      const {remainingSegments} = appData;
      const partsToKeep = remainingSegments.map(seg => [seg.from.origin, seg.to.origin]);

      const socket = new WebSocket(`${baseURL.replace(/^http/, 'ws')}`);
      socket.onopen = () => {
        socket.send(JSON.stringify({videoPath, partsToKeep}));
      };

      socket.onmessage = (event) => {
        const message = JSON.parse(event.data);
        if (message.type === 'progress') {
          setProgress(Number(message.progress) || progress)
          console.log(`进度: ${message.progress}%`);
        } else if (message.type === 'complete') {
          window.open(`${baseURL}/downloadVideo?videoPath=${message.videoPath}`, '_blank');
          setIsExporting(false)
          setProgress(0)
          socket.close();
        }
      };
    } else {
      await exportAudio();
    }
  }, [appData, exportAudio, progress, videoPath]);
  const handleZoomChange = (event, newZoomLevel) => {
    setZoomLevel(newZoomLevel);
    wavesurferRef.current.zoom(newZoomLevel);
  };
  const handleZoomInputChange = (event) => {
    const newZoomLevel = parseInt(event.target.value);
    setZoomLevel(newZoomLevel);
    wavesurferRef.current.zoom(newZoomLevel);
  };
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handlePlayRegions = () => {
    if (!wsRegions || !wsRegions.regions || !Object.keys(wsRegions.regions).length) return;

    const regionsArray = Object.values(wsRegions.regions);
    let currentRegionIndex = 0;

    const playNextRegion = () => {
      if (currentRegionIndex >= regionsArray.length) {
        wavesurferRef.current.pause();
        return
      }
      const region = regionsArray[currentRegionIndex];

      // 将播放头设置到当前 region 的开始位置
      wavesurferRef.current.setTime(region.start);

      // 开始播放
      wavesurferRef.current.play();

      // 监听时间更新事件，确定何时切换到下一个 region
      const handleTimeUpdate = () => {
        const currentTime = wavesurferRef.current.getCurrentTime();

        if (currentTime >= region.end) {
          // 移除时间更新事件的监听器
          wavesurferRef.current.un('audioprocess', handleTimeUpdate);

          // 播放下一个 region
          currentRegionIndex++;
          playNextRegion();
        }
      };

      // 绑定时间更新事件
      wavesurferRef.current.on('audioprocess', handleTimeUpdate);
    };

    playNextRegion();
  };

  useKeyboardShortcuts({handleAutoCut, handleCut, handleExport, handlePlayRegions, wavesurferRef, wsRegions});

  return (
    <Box sx={{padding: 3}}>
      <Stack direction="row" spacing={0} sx={{mb: 0, alignItems: 'center', flexWrap: 'wrap'}}>
        <PlayPause
          onClick={() => wavesurferRef.current.playPause()}
          isPlaying={isPlaying}
        />
        <PlaySlice
          onClick={handlePlayRegions}
          disabled={!wsRegions?.regions || Object.keys(wsRegions.regions).length === 0}
        />
        <CutSlice
          onClick={handleCut}
          disabled={!wsRegions?.regions?.length}
        />
        <AutoCutSilence onClick={handleAutoCut}/>
        <Export
          onClick={handleExport}
          videoPath={videoPath}
          isExporting={isExporting}
          progress={progress}
        />
        <ViewHistory onClick={toggleDrawer}/>
      </Stack>
      <WaveForm>
        <div ref={waveformRef}></div>
      </WaveForm>

      <Transcription
        wsRegions={wsRegions}
        onSelect={regions => {
          removeRegions();
          createOrUpdateRegions(regions);
        }}
      >
        <ZoomSlider zoomLevel={zoomLevel}
                    onZoomChange={handleZoomChange}
                    onZoomInputChange={handleZoomInputChange}
        />
      </Transcription>
      <HistoryPanel
        open={isDrawerOpen}
        onClose={toggleDrawer}
        onRestore={handleRestore}
      />
    </Box>
  );
}

export default MainApp;
