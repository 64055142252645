import {createContext, useContext, useReducer} from 'react';

export const initialState = {
  waveFocused: true
};

export function reducer(state, action) {
  switch (action.type) {
    case 'SET_WAVE_FOCUSED':
      return { ...state, waveFocused: action.payload };
    default:
      return state;
  }
}

const FocusContext = createContext(null);
const DispatchContext = createContext(() => {});
export function useFocusData() {
  return useContext(FocusContext)
}

export function useFocusDispatch() {
  return useContext(DispatchContext)
}

export default function FocusProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState, s => s);

  return (
    <FocusContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        {children}
      </DispatchContext.Provider>
    </FocusContext.Provider>
  )
}
