import {Box, Button, Tooltip} from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import React from "react";

export default function PlaySlice({disabled, onClick}) {
  return (
    <Tooltip title={disabled ? '' : 'Cmd/Ctrl + L'}>
      <Box component="span" sx={{
        display: 'inline-block', mb: 5,
        opacity: disabled ? 0.5 : 1
      }}>
        <Button
          sx={{
            mr: 2,
            backgroundColor: '#0288d1', // 使用亮蓝色
            color: '#ffffff',
            '&:hover': {
              backgroundColor: '#0277bd', // 悬停时颜色变化
            },
            '&:disabled': {
              backgroundColor: '#b0bec5', // 禁用状态颜色
              color: '#ffffff',
            }
          }}
          variant="contained"
          onClick={onClick}
          startIcon={<PlayArrowIcon />}
          disabled={disabled}
        >
          播放选区
        </Button>
      </Box>
    </Tooltip>
  )
}
