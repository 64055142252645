import {Button} from "@mui/material";
import HistoryIcon from "@mui/icons-material/History";
import React from "react";

export default function ViewHistory({ onClick }) {
  return (
    <Button
      sx={{
        mb: 5,
        backgroundColor: '#f5f5f5', // 浅灰色背景，更轻量
        color: '#616161', // 深灰色字体，较柔和
        boxShadow: 'none', // 移除阴影
        '&:hover': {
          backgroundColor: '#e0e0e0' // 悬停时稍微变暗
        },
        border: '1px solid #e0e0e0', // 添加边框以增加轻量感
        padding: '6px 12px', // 轻量化的内边距
        textTransform: 'none' // 保持按钮文本的原样式，不全大写
      }}
      variant="contained"
      onClick={onClick}
      startIcon={<HistoryIcon />}
    >
      操作历史
    </Button>
  )
}
