import {Button, Tooltip} from "@mui/material";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import React from "react";

export default function PlayPause({ onClick, isPlaying }) {
  return (
    <Tooltip title="Space">
      <Button
        sx={{
          mr: 2,
          mb: 5,
          backgroundColor: isPlaying ? '#d32f2f' : '#1976d2', // 根据播放状态调整背景色
          color: '#ffffff', // 白色文字
          '&:hover': {
            backgroundColor: isPlaying ? '#c62828' : '#1565c0' // 悬停时颜色变化
          }
        }}
        variant="contained"
        onClick={onClick}
        startIcon={isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
      >
        {isPlaying ? '暂停' : '播放'}
      </Button>
    </Tooltip>
  )
}
