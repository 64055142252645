import {Box, Button, Tooltip} from "@mui/material";
import ContentCutIcon from "@mui/icons-material/ContentCut";
import React from "react";

export default function CutSlice({ onClick, disabled }) {
  return (
    <Tooltip title={disabled ? '' :"Backspace/Delete"}>
      <Box component="span" sx={{
        display: 'inline-block', mb: 5,
        opacity: disabled ? 0.4 : 1
      }}>
        <Button
          sx={{
            mr: 2,
            backgroundColor: '#d32f2f', // 深红色按钮
            color: '#ffffff',
            '&:hover': {
              backgroundColor: '#c62828' // 悬停时颜色变化
            },
            '&:disabled': {
              backgroundColor: '#e57373', // 禁用状态颜色
              color: '#ffffff',
            }
          }}
          variant="contained"
          onClick={onClick}
          startIcon={<ContentCutIcon />}
          disabled={disabled}
        >
          剪切选区
        </Button>
      </Box>
    </Tooltip>
  )
}
