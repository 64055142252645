import {Box} from "@mui/material";
import React from "react";
import {useFocusData} from "../../contexts/FocusContext";

export default function WaveForm(props) {
  const { waveFocused } = useFocusData()

  return (
    <Box
      sx={{
        backgroundColor: 'white', // 背景色
        borderRadius: '5px', // 圆角边框
        border: '2px solid #ccc',
        ...(waveFocused && {
          borderColor: '#007bff', // 聚焦时改变边框颜色
          boxShadow: '0 0 15px rgba(0, 123, 255, 0.8)', // 聚焦时增强发光效果
        }),
        mb: 5, // 增加按钮和波形之间的垂直间距
      }}
    >
      {props.children}
    </Box>
  )
}
