import React, {useState} from "react";
import {Card, CardContent, Typography, Button, Box, CircularProgress} from "@mui/material";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const UploadCard = ({onFileSelect}) => {
  const [loading, setLoading] = useState(false);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setLoading(true);
      // 模拟上传过程
      await onFileSelect(file);
      setLoading(false);
    }
  };

  // 模拟选择样例音频
  const handleSampleAudioSelect = async () => {
    setLoading(true);
    const response = await fetch('/example-audio.m4a');
    const blob = await response.blob();

    const file = new File([blob], 'example-audio.m4a', {type: blob.type});

    await onFileSelect(file);
    setLoading(false);
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <Card
        sx={{
          minWidth: 275,
          padding: 4,
          textAlign: 'center'
        }}
        elevation={3}
      >
        <CardContent>
          <CloudUploadIcon fontSize="large" color="primary"/>
          <Typography variant="h5" component="div" sx={{mt: 2}}>
            上传音视频文件
          </Typography>
          <Typography sx={{mb: 2}} color="text.secondary">
            拖拽或点击下面按钮选择文件
          </Typography>
          <Box>  {/* 使用 Box 使按钮独占一行 */}
            <Button
              variant="contained"
              component="label"
              startIcon={<CloudUploadIcon/>}
              disabled={loading} // 上传时禁用按钮
            >
              选择文件
              <input
                type="file"
                hidden
                accept="audio/*,video/*"
                onChange={handleFileChange}
              />
            </Button>
          </Box>

          {/* 添加选择样例音频的按钮 */}
          <Button
            sx={{mt: 2}}
            variant="text"
            onClick={handleSampleAudioSelect}
          >
            使用样例音频
          </Button>

          {loading && (
            <Box mt={2}>
              <CircularProgress/> {/* 显示加载指示器 */}
              <Typography>上传中...</Typography>
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default UploadCard;
