import React, {useMemo, useState, useTransition} from 'react';
import {TextField} from '@mui/material';
import debounce from 'lodash/debounce';
import {useFocusDispatch} from "../../contexts/FocusContext";

const SearchInput = ({onSearchChange}) => {
  const [inputValue, setInputValue] = useState('');
  const [, startTransition] = useTransition()
  const dispatch = useFocusDispatch()

  const debouncedSearchChange = useMemo(() =>
      debounce(onSearchChange, 300),
    [onSearchChange]
  );

  const handleChange = (e) => {
    const value = e.target.value.toLowerCase();
    e.stopPropagation()
    setInputValue(value);
    startTransition(() => {
      debouncedSearchChange(value); // 使用 debounce 后的函数
    })
  };

  const handleFocus = () => {
    startTransition(() => {
      dispatch({type: 'SET_WAVE_FOCUSED', payload: false})
    })
  };
  const handleBlur = () => {
    startTransition(() => {
      dispatch({type: 'SET_WAVE_FOCUSED', payload: true})
    })
  }
  const stopKeyEventPropagation = (event) => {
    event.stopPropagation(); // 阻止键盘事件的传播
  };
  return (
    <div
      onKeyDown={stopKeyEventPropagation}
      onKeyUp={stopKeyEventPropagation}
    >
      <TextField
        style={{width: '100%'}}
        label="搜索字词"
        variant="outlined"
        value={inputValue}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        sx={{marginBottom: 2}}
      />
    </div>
  );
};

export default SearchInput;
