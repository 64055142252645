export const filterTranscriptionData = (data, cuts, cutPointer) => {
  return data.filter(word => {
    for (let i = 0; i <= cutPointer; i++) {
      for (const [start, end] of cuts[i]) {
        if (
          (word.start >= start && word.start < end) ||  // 字词的起始时间在剪辑片段内
          (word.end > start && word.end <= end) ||      // 字词的结束时间在剪辑片段内
          (word.start < start && word.end > end)        // 字词跨越整个剪辑片段
        ) {
          return false;
        }
      }
    }
    return true;
  });
};
