import React, {useMemo, useState} from 'react';
import {Box} from '@mui/material';
import {mapOriginalToCurrent} from '../../utils/audioUtils';
import {useAppData} from "../../contexts/AppContext";
import {filterTranscriptionData} from "../../utils/transcription";
import {removeRegions} from '../../utils/region';
import SearchInput from './SearchInput';
import Word from "./Word"; // 引入新组件

const Transcription = ({wsRegions, onSelect, children}) => {
  const [lastSelectedIndex, setLastSelectedIndex] = useState(null);
  const {transcriptionData, cuts, cutPointer, totalDuration} = useAppData();
  const data = useMemo(() => filterTranscriptionData(transcriptionData, cuts, cutPointer), [transcriptionData, cuts, cutPointer]);

  const handleSearchChange = (term) => {
    if (term) {
      // 过滤符合搜索条件的词语
      const matchedWords = data.filter(wordData =>
        wordData.word.toLowerCase().includes(term)
      );

      // 构建匹配词语的 start 和 end 数组
      const regions = matchedWords.map(wordData => ({
        start: mapOriginalToCurrent(wordData.start, cuts, totalDuration, cutPointer),
        end: mapOriginalToCurrent(wordData.end, cuts, totalDuration, cutPointer)
      }));

      // 如果有匹配的词语，调用 onSelect 更新高亮词语
      if (regions.length > 0) {
        onSelect(regions);
      }
    } else {
      // 如果搜索框为空，清除高亮
      removeRegions();
      setLastSelectedIndex(null);
    }
  };

  const handleWordClick = (index, wordData, event) => {
    if (wordData.isHighlighted) {
      removeRegions();
      setLastSelectedIndex(null);
    } else {
      let start, end;
      if (lastSelectedIndex !== null && event.shiftKey) {
        const from = Math.min(lastSelectedIndex, index);
        const to = Math.max(lastSelectedIndex, index);
        const newSelectedWords = data.slice(from, to + 1);
        start = newSelectedWords[0].start;
        end = newSelectedWords[newSelectedWords.length - 1].end;
      } else {
        start = wordData.start;
        end = wordData.end;
      }
      start = mapOriginalToCurrent(start, cuts, totalDuration, cutPointer);
      end = mapOriginalToCurrent(end, cuts, totalDuration, cutPointer);
      onSelect([{start, end}]);
      setLastSelectedIndex(index);
    }
  };

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
      <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start'}}>
        <Box sx={{width: 300}}>
          <SearchInput onSearchChange={handleSearchChange}/>
        </Box>
        {children}
      </Box>
      <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 1}}>
        {data?.map((wordData, index) => (
          <Word key={wordData.id}
                data={wordData}
                wsRegions={wsRegions}
                onClick={(e, isHighlighted) => {
                  if (e.key !== ' ') {
                    handleWordClick(
                      index,
                      {...wordData, isHighlighted},
                      e
                    );
                  }
                }}
          />
        ))}
      </Box>
    </Box>
  );
};

export default Transcription;
