const detectSilence = (audioBuffer, maxSilenceDuration) => {
  const threshold = 0.1; // 静音阈值
  const sampleRate = audioBuffer.sampleRate;
  const maxSilenceSamples = maxSilenceDuration * sampleRate;

  let silenceStart = null;
  const silenceSegments = [];

  const channelData = audioBuffer.getChannelData(0); // 仅处理单声道

  for (let i = 0; i < channelData.length; i++) {
    if (Math.abs(channelData[i]) < threshold) {
      if (silenceStart === null) {
        silenceStart = i;
      }
    } else {
      if (silenceStart !== null) {
        const silenceDuration = i - silenceStart;
        if (silenceDuration >= maxSilenceSamples) {
          const startTimestamp = silenceStart / sampleRate;
          const endTimestamp = i / sampleRate;
          silenceSegments.push([startTimestamp, endTimestamp]);
        }
        silenceStart = null;
      }
    }
  }

  // 处理音频末尾的静音段
  if (silenceStart !== null && (channelData.length - silenceStart) >= maxSilenceSamples) {
    const startTimestamp = silenceStart / sampleRate;
    const endTimestamp = channelData.length / sampleRate;
    silenceSegments.push([startTimestamp, endTimestamp]);
  }

  return silenceSegments;
};

export const detectSilenceToCut = (audioBuffer, maxSilenceDuration, silenceDurationToKeep) => {
  const silences = detectSilence(audioBuffer, maxSilenceDuration);

  const segmentsToCut = [];

  silences.forEach(([start, end]) => {
    const silenceDuration = end - start;

    if (silenceDuration > silenceDurationToKeep) {
      const halfToCut = (silenceDuration - silenceDurationToKeep) / 2;
      const cutStart = (start + end) / 2 - halfToCut;
      const cutEnd = (start + end) / 2 + halfToCut;
      segmentsToCut.push([cutStart, cutEnd]);
    }
  });

  return segmentsToCut;
};

export const bufferToWave = (buffer) => {
  const numOfChan = buffer.numberOfChannels,
    length = buffer.length * numOfChan * 2 + 44,
    bufferData = new ArrayBuffer(length),
    view = new DataView(bufferData),
    channels = [],
    sampleRate = buffer.sampleRate;

  let offset = 0;

  const writeString = (str) => {
    for (let i = 0; i < str.length; i++) {
      view.setUint8(offset++, str.charCodeAt(i));
    }
  };

  const setUint16 = (data) => {
    view.setUint16(offset, data, true);
    offset += 2.
  };

  const setUint32 = (data) => {
    view.setUint32(offset, data, true);
    offset += 4;
  };

  writeString('RIFF');
  setUint32(length - 8);
  writeString('WAVE');
  writeString('fmt ');
  setUint32(16);
  setUint16(1);
  setUint16(numOfChan);
  setUint32(sampleRate);
  setUint32(sampleRate * numOfChan * 2);
  setUint16(numOfChan * 2);
  setUint16(16);
  writeString('data');
  setUint32(length - offset - 4);

  for (let i = 0; i < buffer.numberOfChannels; i++) {
    channels.push(buffer.getChannelData(i));
  }

  let sample;
  while (offset < length) {
    for (let i = 0; i < numOfChan; i++) {
      sample = Math.max(-1, Math.min(1, channels[i][(offset / 2 / numOfChan) | 0]));
      sample = (sample < 0 ? sample * 32768 : sample * 32767) | 0;
      view.setInt16(offset, sample, true);
      offset += 2;
    }
  }

  return new Blob([bufferData], { type: 'audio/wav' });
};


/**
 * Cuts specified segments from a given audio buffer and returns an audio Blob containing the resulting audio data.
 *
 * @param {AudioBuffer} audioBuffer - The input audio buffer to cut segments from.
 * @param {AudioContext} audioContext - The audio context used for creating the new audio buffer.
 * @param {Array<Array<number>>} cutSegments - An array of segments to cut, where each segment is defined by a start and end time in seconds.
 * @returns {Promise<Blob>} A promise that resolves to a Blob containing the audio data of the cut segments.
 *
 * @example
 * const audioBuffer = ...;  // An AudioBuffer object
 * const audioContext = new (window.AudioContext || window.webkitAudioContext)();
 * const cutSegments = [
 *   [10, 20],  // Cut segment from 10 seconds to 20 seconds
 *   [30, 40],  // Cut segment from 30 seconds to 40 seconds
 * ];
 *
 * cutAudio(audioBuffer, audioContext, cutSegments).then((audioBlob) => {
 *   // Do something with the resulting audio Blob
 *   console.log(audioBlob);
 * });
 *
 * // Output:
 * // Blob { size: 123456, type: "audio/wav" }
 */
export const cutAudio = async (audioBuffer, audioContext, cutSegments) => {
  const sampleRate = audioBuffer.sampleRate;
  let remainingSegments = [{ from: 0, to: audioBuffer.duration }];

  cutSegments.sort((a, b) => a[0] - b[0]);

  cutSegments.forEach(([start, end]) => {
    const newSegments = [];

    remainingSegments.forEach(segment => {
      if (start >= segment.to || end <= segment.from) {
        newSegments.push(segment);
      } else {
        if (start > segment.from) {
          newSegments.push({ from: segment.from, to: start });
        }
        if (end < segment.to) {
          newSegments.push({ from: end, to: segment.to });
        }
      }
    });

    remainingSegments = newSegments;
  });

  const newBufferLength = remainingSegments.reduce((sum, segment) => {
    const start = segment.from * sampleRate;
    const end = segment.to * sampleRate;
    const segmentLength = audioBuffer.getChannelData(0).subarray(start, end).length;
    return sum + segmentLength;
  }, 0);

  const newBuffer = audioContext.createBuffer(
    1,
    newBufferLength,
    sampleRate
  );
  const newChannelData = newBuffer.getChannelData(0);

  let offset = 0;
  remainingSegments.forEach(segment => {
    const start = segment.from * sampleRate;
    const end = segment.to * sampleRate;
    const segmentData = audioBuffer.getChannelData(0).subarray(start, end);
    newChannelData.set(segmentData, offset);
    offset += segmentData.length;
  });

  return bufferToWave(newBuffer);
};

/**
 * Calculates the remaining audio segments after cutting specified segments from the total duration.
 *
 * @param {number} totalDuration - The total duration of the original audio in seconds.
 * @param {Array<Array<number>>} cutSegments - An array of segments to cut, where each segment is defined by a start and end time in seconds.
 * @returns {Array<Object>} An array of remaining segments. Each segment is an object with `from` and `to` properties, which have `origin` and `current` times in seconds.
 *
 * @example
 * const totalDuration = 60;  // Total duration of the audio in seconds
 * const cutSegments = [
 *   [10, 20],  // Cut segment from 10 seconds to 20 seconds
 *   [30, 35],  // Cut segment from 30 seconds to 35 seconds
 * ];
 *
 * const remainingSegments = getRemainingSegments(totalDuration, cutSegments);
 * console.log(remainingSegments);
 *
 * // Output:
 * // [
 * //   { from: { origin: 0, current: 0 }, to: { origin: 10, current: 10 } },
 * //   { from: { origin: 20, current: 10 }, to: { origin: 30, current: 20 } },
 * //   { from: { origin: 35, current: 20 }, to: { origin: 60, current: 45 } }
 * // ]
 */
export const getRemainingSegments = (totalDuration, cutSegments) => {
  cutSegments.sort((a, b) => a[0] - b[0]);

  const mergedSegments = [];
  for (const segment of cutSegments) {
    if (mergedSegments.length === 0 || mergedSegments[mergedSegments.length - 1][1] < segment[0]) {
      mergedSegments.push(segment);
    } else {
      mergedSegments[mergedSegments.length - 1][1] = Math.max(mergedSegments[mergedSegments.length - 1][1], segment[1]);
    }
  }

  const remainingSegments = [];
  let currentStart = 0;
  let currentDuration = 0;

  for (const segment of mergedSegments) {
    if (currentStart < segment[0]) {
      remainingSegments.push({
        from: { origin: currentStart, current: currentDuration },
        to: { origin: segment[0], current: currentDuration + (segment[0] - currentStart) }
      });
      currentDuration += (segment[0] - currentStart);
    }
    currentStart = segment[1];
  }

  if (currentStart < totalDuration) {
    remainingSegments.push({
      from: { origin: currentStart, current: currentDuration },
      to: { origin: totalDuration, current: currentDuration + (totalDuration - currentStart) }
    });
  }

  return remainingSegments;
};

/**
 * Maps cut segments from the modified audio back to their original positions in the original audio.
 *
 * @param {Array<Object>} remainingSegments - An array of remaining segments after previous cuts. Each segment is an object with `from` and `to` properties, which have `origin` and `current` times in seconds.
 * @param {Array<Array<number>>} cutSegments - An array of new segments to cut from the modified audio, where each segment is defined by a start and end time in seconds.
 * @returns {Array<Array<number>>} An array of mapped cut segments with their positions in the original audio.
 *
 * @example
 * const remainingSegments = [
 *   { from: { origin: 0, current: 0 }, to: { origin: 10, current: 10 } },
 *   { from: { origin: 20, current: 10 }, to: { origin: 30, current: 20 } },
 *   { from: { origin: 35, current: 20 }, to: { origin: 60, current: 45 } }
 * ];
 * const cutSegments = [
 *   [5, 15],  // Cut segment from 5 seconds to 15 seconds in the modified audio
 *   [25, 35]  // Cut segment from 25 seconds to 35 seconds in the modified audio
 * ];
 *
 * const mappedCuts = mapCutSegmentsToOriginal(remainingSegments, cutSegments);
 * console.log(mappedCuts);
 *
 * // Output:
 * // [
 * //   [5, 20],  // Mapped cut segment in the original audio
 * //   [30, 50]  // Mapped cut segment in the original audio
 * // ]
 */
export const mapCutSegmentsToOriginal = (remainingSegments, cutSegments) => {
  const mappedCuts = [];

  cutSegments.forEach(cutSegment => {
    const [cutStart, cutEnd] = cutSegment;
    let mappedStart = null;
    let mappedEnd = null;

    let currentDuration = 0;

    for (const segment of remainingSegments) {
      const { from, to } = segment;
      const segmentLength = to.current - from.current;

      if (mappedStart === null && cutStart >= currentDuration && cutStart < currentDuration + segmentLength) {
        mappedStart = from.origin + (cutStart - currentDuration);
      }

      if (mappedEnd === null && cutEnd >= currentDuration && cutEnd < currentDuration + segmentLength) {
        mappedEnd = from.origin + (cutEnd - currentDuration);
      }

      currentDuration += segmentLength;

      if (mappedStart !== null && mappedEnd !== null) {
        break;
      }
    }

    if (mappedStart !== null && mappedEnd === null) {
      mappedEnd = remainingSegments[remainingSegments.length - 1].to.origin;
    }

    if (mappedStart !== null && mappedEnd !== null) {
      mappedCuts.push([mappedStart, mappedEnd]);
    }
  });

  return mappedCuts;
};

/**
 * 将原始音频时间映射到当前剪辑后的音频时间
 * @param {number} originalTime - 原始音频的时间
 * @param {Array<Array<number>>} cuts - 剪辑操作数组
 * @param {number} totalDuration - 原始音频的总长度
 * @param {number} cutPointer - 当前剪辑指针
 * @returns {number} 当前剪辑后音频的时间
 */
export const mapOriginalToCurrent = (originalTime, cuts, totalDuration, cutPointer) => {
  function mergeAudioSegments(segments) {
    // 把三维数组拍平成二维数组
    const flattenedSegments = segments.flat();

    // 对音频片段按起始时间进行排序
    flattenedSegments.sort((a, b) => a[0] - b[0]);

    // 用来保存合并后的结果
    const mergedSegments = [];

    // 遍历排序后的片段数组，进行合并处理
    flattenedSegments.forEach(segment => {
      const [start, end] = segment;

      // 如果结果数组为空或者当前片段与最后一个片段不重叠，则直接添加
      if (mergedSegments.length === 0 || mergedSegments[mergedSegments.length - 1][1] < start) {
        mergedSegments.push([start, end]);
      } else {
        // 否则，合并当前片段和最后一个片段
        mergedSegments[mergedSegments.length - 1][1] = Math.max(mergedSegments[mergedSegments.length - 1][1], end);
      }
    });

    return mergedSegments;
  }

  let currentTime = originalTime;
  let cutTime = 0;

  const segments = mergeAudioSegments(cuts.slice(0, cutPointer + 1))
  for (const [start, end] of segments) {
    if (originalTime > start) {
      if (originalTime < end) {
        return cutTime + (originalTime - start);
      }
      cutTime += (end - start);
    }
  }
  return currentTime - cutTime;
};
