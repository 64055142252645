import {Backdrop, Box, Button, LinearProgress, Tooltip, Typography} from "@mui/material";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import React from "react";

export default function Export({
                                 onClick, videoPath,
                                 isExporting, progress
                               }) {
  return (
    <Box>
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.85)' // 调整背景颜色，使其更深
        }}
        open={isExporting}
      >
        {/* 设置 LinearProgress 的宽度 */}
        <Box sx={{width: '50%', mb: 2}}>
          <LinearProgress variant="determinate" value={progress}/>
        </Box>
        <Typography variant="h6" sx={{mt: 2}}>
          正在导出: {progress}%
        </Typography>
      </Backdrop>
      <Tooltip title="Cmd/Ctrl + Shift + O">
        <Button
          sx={{
            mr: 2,
            mb: 5,
            backgroundColor: '#388e3c', // 成功按钮使用绿色
            color: '#ffffff',
            '&:hover': {
              backgroundColor: '#2e7d32' // 悬停时颜色变化
            }
          }}
          variant="contained"
          onClick={onClick}
          startIcon={<SaveAltIcon/>}
        >
          导出{videoPath ? '视频' : '音频'}
        </Button>
      </Tooltip>

    </Box>
  )
}
