import { useEffect } from 'react';

const useKeyboardShortcuts = ({ handleAutoCut, handleCut, handleExport, handlePlayRegions, wavesurferRef, wsRegions }) => {
  useEffect(() => {
    const handleKeyDown = async (event) => {
      const isMac = navigator.userAgent.toUpperCase().includes('MAC');
      const deleteKey = event.key === 'Backspace' || event.key === 'Delete';
      const cmdOrCtrlKey = isMac ? event.metaKey : event.ctrlKey;

      if (cmdOrCtrlKey && event.shiftKey && deleteKey) {
        event.preventDefault();
        event.stopPropagation();
        await handleAutoCut();
        console.log("Auto cut silence invoked");
      } else if (deleteKey) {
        if (wsRegions && wsRegions.regions && Object.keys(wsRegions.regions).length > 0) {
          await handleCut();
        }
      } else if (cmdOrCtrlKey && event.shiftKey && event.key.toUpperCase() === 'O') {
        event.preventDefault();
        event.stopPropagation();
        await handleExport();
      } else if (cmdOrCtrlKey && event.key.toUpperCase() === 'L') {
        event.preventDefault();
        event.stopPropagation();
        handlePlayRegions();
      }

      if (event.key === ' ') {
        event.preventDefault();
        event.stopPropagation();
        if (wavesurferRef.current) {
          await wavesurferRef.current.playPause();
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleAutoCut, handleCut, handleExport, handlePlayRegions, wavesurferRef, wsRegions]);
};

export default useKeyboardShortcuts;
