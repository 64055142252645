import {Box, Grid, Input, Slider, Typography} from "@mui/material";
import React from "react";

export default function ZoomSlider({ zoomLevel, onZoomChange, onZoomInputChange }) {
  return (
    <Box sx={{width: 250}}>
      <Grid container spacing={2} sx={{ alignItems: 'center' }}>
        <Grid item>
          <Typography>
            缩放
          </Typography>
        </Grid>
        <Grid item xs>
          <Slider
            value={typeof zoomLevel === 'number' ? zoomLevel : 0}
            onChange={onZoomChange}
            aria-labelledby="zoom-slider"
          />
        </Grid>
        <Grid item>
          <Input
            value={zoomLevel}
            onChange={onZoomInputChange}
            size="small"
            inputProps={{
              step: 10,
              min: 0,
              max: 100,
              type: 'number',
              'aria-labelledby': 'input-slider',
            }}
          />
        </Grid>
      </Grid>
    </Box>
  )
}
