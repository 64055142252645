import RegionsPlugin from "wavesurfer.js/plugins/regions";

let wsRegions;
let regionsListener;

const registerRegionPlugin = (wavesurferRef, listener) => {
  const random = (min, max) => Math.random() * (max - min) + min;
  const randomColor = () => `rgba(${random(0, 255)}, ${random(0, 255)}, ${random(0, 255)}, 0.5)`;
  const loop = false;

  wsRegions = wavesurferRef.current.registerPlugin(RegionsPlugin.create());

  listener(wsRegions)

  wsRegions.enableDragSelection({
    color: 'rgba(255, 0, 0, 0.1)',
  });

  let activeRegion = null;
  wsRegions.on('region-in', (region) => {
    activeRegion = region;
  });
  wsRegions.on('region-out', (region) => {
    if (activeRegion === region) {
      if (loop) {
        console.log('region play')
        region.play();
      } else {
        activeRegion = null;
      }
    }
  });
  wsRegions.on('region-clicked', (region, e) => {
    e.stopPropagation();
    activeRegion = region;
    region.setOptions({ color: randomColor() });
  });

  return wsRegions;
};

const removeRegion = (region) => {
  region.remove();
};

const handleRegionEvents = (e) => {
  const wsRegionsCopy = Object.create(
    Object.getPrototypeOf(wsRegions),
    Object.getOwnPropertyDescriptors(wsRegions)
  );
  regionsListener(wsRegionsCopy, e);
};

const registerRegionEvents = () => {
  wsRegions.on('region-created', handleRegionEvents);
  wsRegions.on('region-updated', handleRegionEvents);
  wsRegions.on('region-removed', handleRegionEvents);
  wsRegions.on('region-double-clicked', removeRegion);
};

const unregisterRegionEvents = () => {
  wsRegions.un('region-created', handleRegionEvents);
  wsRegions.un('region-updated', handleRegionEvents);
  wsRegions.un('region-removed', handleRegionEvents);
  wsRegions.un('region-double-clicked', removeRegion);
};

const subscribeRegions = (cb) => {
  regionsListener = cb;
};

export const startFreshRegions = (wavesurferRef, listener) => {
  if (wsRegions) {
    unregisterRegionEvents();
    wsRegions.destroy();
  }
  wsRegions = registerRegionPlugin(wavesurferRef, listener);
  registerRegionEvents();
  subscribeRegions(listener);
  return wsRegions;
};

export const createOrUpdateRegions = (regionsArray) => {
  regionsArray.forEach(({ start, end }) => {
    const existingRegion = wsRegions.regions.find(region => region.start === start && region.end === end);
    if (!existingRegion) {
      wsRegions.addRegion({
        start: start,
        end: end,
        color: 'rgba(0, 123, 255, 0.5)',
      });
    }
  });
};

export const removeRegions = () => {
  wsRegions.clearRegions();
};
